/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

interface IProps {
  title: string;
}

function SEO({ title }: IProps) {
  return <Helmet title={title} />;
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;
